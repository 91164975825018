import { container, title } from 'assets/jss/material-kit-react';

const notFoundPageStyle = () => ({
  container: {
    zIndex: '12',
    color: '#FFFFFF',
    ...container,
    textAlign: 'center',
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: '#FFFFFF',
    textDecoration: 'none',
  },
  subtitle: {
    fontSize: '1.313rem',
    maxWidth: '500px',
    margin: '10px auto 0',
  },
});

export default notFoundPageStyle;
