import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Button from 'components/CustomButtons/Button';
import Layout from 'components/Layout';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Parallax from 'components/Parallax/Parallax';
import SEO from 'components/SEO';

import backgroundImage from 'assets/img/404-bg.jpg';
import notFoundPageStyle from 'assets/jss/material-kit-react/views/notFoundPage';

const NotFoundPage = ({ classes }) => {
  return (
    <>
      <Parallax filter image={backgroundImage}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>Page not found</h1>
                <h3 className={classes.subtitle}>You just hit a route that doesn&#39;t exist...</h3>
                <p>
                  <a href="/">
                    <Button size="lg" color="primary">Return to homepage</Button>
                  </a>
                </p>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
    </>
  );
};

export default withStyles(notFoundPageStyle)(NotFoundPage);
